'use strict';

$(document).ready(function() {

    plyr.setup();

    $(".hover-btn1").on('mouseup', function() {

        $(".hover-btn").hide();


    });
    $(".box1").on('mouseleave', function() {

        $(".hover-btn").show();


    });


    $("div.hover-btn").on('click', function() {

        $("div.hover-btn").hide();


    });

});
