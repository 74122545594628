//leftmenu 
$(document).ready(function () {
  var trigger = $('.hamburger'),
      overlay = $('.overlay'),
     isClosed = false;

    trigger.on('click',function () {
      hamburger_cross();      
    });

    function hamburger_cross() {

      if (isClosed == true) { 
        trigger.removeClass('is-open');
        trigger.addClass('is-closed');
        isClosed = false;
      } else {   
        trigger.removeClass('is-closed');
        trigger.addClass('is-open');
        isClosed = true;
      }
  }
  
  $('[data-toggle="offcanvas"]').on('click',function () {
        $('#wrapper').toggleClass('toggled');

  });
  $(".back-icon").on('click',function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });  
});


